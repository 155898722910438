import React, { useState } from 'react';

export default function NavBar () {
  const[navBarExtended, extendNavBar] = useState(false)

  // Logo Render
  const Logo = () => {
    const productName = '1:MANY'
    return (
      <div className="flex items-center pl-4">
        <a className="text-2xl font-bold no-underline toggleColour text-scheme-text hover:no-underline lg:text-4xl"  href="https://onetomany.dev"> 
          <svg className="inline h-8 pr-2 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.005 512.005">
            <path fill="#F46036" d="m407 .5h-302c-57.898438 0-105 47.101562-105 105v162.171875c0 46.199219 30.332031 86.4375 74.285156 99.316406l50.710938 50.714844c2.816406 2.8125 6.628906 4.394531 10.609375 4.394531 3.976562 0 7.792969-1.582031 10.605469-4.394531l46.519531-46.523437h214.269531c57.898438 0 105-47.101563 105-105v-160.679688c0-57.898438-47.101562-105-105-105zm75 265.679688c0 41.355468-33.644531 75-75 75h-220.480469c-3.976562 0-7.792969 1.582031-10.605469 4.394531l-40.308593 40.308593-42.929688-42.929687c-1.925781-1.925781-4.339843-3.292969-6.984375-3.949219-32.789062-8.160156-55.691406-37.492187-55.691406-71.332031v-162.171875c0-41.355469 33.644531-75 75-75h302c41.355469 0 75 33.644531 75 75zm0 0"/>
            <path fill="#F46036" d="m351.242188 144.328125h-190.484376c-8.285156 0-15 6.71875-15 15 0 8.285156 6.714844 15 15 15h190.484376c8.285156 0 15-6.714844 15-15 0-8.28125-6.714844-15-15-15zm0 0"/>
            <path fill="#F46036" d="m351.242188 197.351562h-190.484376c-8.285156 0-15 6.714844-15 15 0 8.285157 6.714844 15 15 15h190.484376c8.285156 0 15-6.714843 15-15 0-8.285156-6.714844-15-15-15zm0 0"/>
          </svg>
          {productName}
        </a>
      </div>
    )
  }

  const appLink = 'https://app.onetomany.dev'

  return (
    <nav id="header" style={{backgroundColor: "#171123"}} className="fixed top-0 z-30 w-full text-white">
      <div className="container flex flex-wrap items-center justify-between w-full py-2 mx-auto mt-0">
        <Logo />
        
        <div className="block pr-4 lg:hidden">
          <button onClick={() => extendNavBar(!navBarExtended)} id="nav-toggle" className="flex items-center p-1 text-primaryText hover:text-white">
            <svg 
              className="w-6 h-6 fill-current" 
              viewBox="0 0 20 20" 
              xmlns="http://www.w3.org/2000/svg">
                <title>
                  Menu
                </title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
            </svg>
          </button>
        </div>

        {navBarExtended 
          ? (<div className="flex-grow w-full pt-6">
            <ul className="flex flex-col items-center flex-1 mb-3 list-reset">
              {/* <li className="mr-3">
                <a className="inline-block px-4 py-2 no-underline hover:text-underline text-primaryText" href="#pricing">Plans & Pricing</a>
              </li> */}
              <li className="mr-3">
                <a className="inline-block px-4 py-2 no-underline hover:text-underline text-primaryText" href="https://calendly.com/lachlanyoungau" rel="noopener noreferrer">Contact A Founder</a>
              </li>
              <li className="mr-3">
                <a className="inline-block px-4 py-2 no-underline hover:text-underline text-primaryText" href={appLink} rel="noopener noreferrer">My Account</a>
              </li>
            </ul>
          </div>) 
          : null
        }

        <div className={"w-full flex-grow lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20 hidden"} id="nav-content">
          <ul className="items-center justify-end flex-1 list-reset lg:flex">
            {/* <li>
              <a className="inline-block py-2 no-underline hover:text-underline text-primaryText" href="#pricing">Plans & Pricing</a>
            </li> */}
            {/* <li className="px-2 mx-1 text-primaryText">|</li> */}
            <li className="mr-3">
              <a className="inline-block py-2 pr-4 no-underline hover:text-underline text-primaryText" href="https://calendly.com/lachlanyoungau">Contact a Founder</a>
            </li>
          </ul>
          <button
            id="navAction" 
            onClick={() => window.open(appLink, "_self")}
            className="px-8 py-2 mx-auto mt-4 font-bold rounded-full bg-primaryText lg:mx-0 hover:underline text-secondaryText lg:mt-0"
          >
            App
          </button>
        </div>
      </div>
      <hr className="py-0 my-0 border-b border-gray-100 opacity-25" />
    </nav>
  )
}
