import React, { useState } from 'react';

export default function EmailCapture () {
  const [ email, updateEmail ] = useState('')
  const [ valid, updateValidity ] = useState(true)
  const [ submitted, hasBeenSubmitted ] = useState(false)

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  }

  const handleValidity = (status) => {
    updateValidity(status)
    if(!status) {
        const timer = setTimeout(() => {
        updateValidity(!status)
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      hasBeenSubmitted(true)
      // Send to Netlify forms
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "signup",
          ...{ email }
        })
      })
    }
  };

  // If the email is invalid, we shake the input box and turn it red
  const successMessage = "🎉 Thanks! We've sent you an email! 🎉" || "🚧 We'll send you an email, \nwhen all systems are go! 🚧`"

  return (
    <form
      name="signup"
      method="post"
      action="/signup"
      data-netlify="true"
      netlify
      onSubmit={(e) => {
        e.preventDefault()
        handleValidity(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email))
      }}
      className="flex flex-row justify-space-between my-6 h-12 w-full z-10"
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="signup" />
      <input 
        className={`
          flex-grow rounded-l-lg border-solid border-2 pl-2 outline-none
          ${submitted ? "border-green-600" : valid ? "border-primaryText" : "border-red-600 animate-shake"}
        `}
        disabled={submitted}
        value={`${submitted ? successMessage : email}`}
        onChange={(e) => updateEmail(e.target.value)} 
      />
      <button 
        type="submit"
        disabled={submitted}
        className={`
          text-white font-bold rounded-r-lg px-8 shadow-lg focus:outline-none
          ${submitted ? "bg-green-600" : valid ? "bg-primaryText" : "bg-red-600 animate-shake"}
        `} 
      >
        {submitted ? "Thanks!" : "Subscribe"}
      </button>
    </form>
  )
}
