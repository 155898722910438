import React from 'react';

import ProductDemo from './productDemo.svg'
import Safe from './safe.svg'

export default function Features() {
    return (
      <div class="container max-w-5xl mx-auto m-8">
        <div class="flex flex-wrap">
          <div class="w-5/6 sm:w-1/2 p-6">
            <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">Multiple Mobile Numbers</h3>
            <p class="text-gray-600 mb-8">Accounts with MFA are designed around a one to one relationship. However what do you do when only one Mobile Number can be provided?</p>
            <p class="text-gray-600 mb-8"><span class="text-primaryText font-bold">Onetomany</span> will aggregate all your MFA codes into <span class="text-tertiary font-bold">one</span>, easy to use dashboard, in order to easily share one account between <span class="text-tertiary font-bold">many</span> users.</p>
          </div>
          <div class="w-full sm:w-1/2 p-6">
            <img src={ProductDemo} style={{objectFit: "cover"}} alt="React Logo" />
          </div>
        </div>
        
        
        <div class="flex flex-wrap flex-col-reverse sm:flex-row">	
          <div class="w-full sm:w-1/2 p-6 mt-6">
            <img src={Safe} style={{objectFit: "cover"}} alt="React Logo" />
          </div>
          <div class="w-full sm:w-1/2 p-6 mt-6">
            <div class="align-middle">
              <h3 class="text-3xl text-gray-800 font-bold leading-none mb-3">Encrypted Dashboard Access</h3>
              <p class="text-gray-600 mb-8"><span class="text-primaryText font-bold">Onetomany</span> is a secure extension to your current MFA systems. There's no need to worry as you send your MFA texts to organised <span class="text-primaryText font-bold">onetomany</span> dashboards, where all access is equally secured with modern MFA options.</p>
              <p class="text-gray-600 mb-8">Add/Remove access to your MFA methods as you need, all at the click of a button.</p>
            </div>
          </div>
        </div>
      </div>
    )
}
