import React from 'react';

// Components
import NavBar from '../../components/navbar'
// import PlansAndPricing from './pricing'
import Features from './features'
import SplashPage from './splashpage'

export default function HomePage () {
  return (
    <div>
      <NavBar />
      <section id="home">
        <SplashPage />
      </section>

      {/* Features Section */}
      <section class="bg-gradient-to-b from-offWhite to-white border-b border-primaryText py-8">
        <Features />
      </section>

      {/* Plans & Pricing */}
      {/* <section id="pricing" class="bg-white py-8">
        <PlansAndPricing />
      </section> */}
    </div>
  )
}
